import { ApiCore } from "./ApiCoreService";

const API_URL = process.env.REACT_APP_API_URL;

// Example usage in a React component or module
export function login(body) {
  let url = `${API_URL}/login`;

  return new Promise((resolve, reject) => {
    new ApiCore().post(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function forgetpassword(body) {
  let url = `${API_URL}/forget-password`;

  return new Promise((resolve, reject) => {
    new ApiCore().post(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function otpVerify(body) {
  let url = `${API_URL}/otp-verify`;

  return new Promise((resolve, reject) => {
    new ApiCore().post(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function changePassword(body) {
  let url = `${API_URL}/change-password`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function resetPassword(body) {
  let url = `${API_URL}/reset-password`;

  return new Promise((resolve, reject) => {
    new ApiCore().post(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function refreshToken() {
  let url = `${API_URL}/refresh`;
  let body;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function travelRequest(body) {
  let url = `${API_URL}/travel-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function generalRequest(body, requestPolicy) {
  let url = `${API_URL}/request-cost-center`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuthWithPolicy(url, body, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function hotelRequest(body, requestPolicy) {
  let url = `${API_URL}/request-hotel`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuthWithPolicy(url, body, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function getHotelData(id, type, requestPolicy) {
  let url = `${API_URL}/request-hotel`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id, type, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function perDiemRequest(body, requestPolicy) {
  let url = `${API_URL}/request-perdiem`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuthWithPolicy(url, body, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function cashInAdvance(body, requestPolicy) {
  let url = `${API_URL}/request-advcash`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuthWithPolicy(url, body, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function transport(body) {
  let url = `${API_URL}/request-transport`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function requestPolicy(id) {
  let url = `${API_URL}/get-request-policy`;
  return new Promise((resolve, reject) => {
    new ApiCore().getgeneralRequest(url,id).then((res) => {
      resolve(res);
    });
  });
}
export function getprofileData() {
  let url = `${API_URL}/get-profile`;

  return new Promise((resolve, reject) => {
    new ApiCore().getgeneral(url).then((res) => {
      resolve(res);
    });
  });
}
export function getgeneralData(id, type, requestPolicy) {
  let url = `${API_URL}/request-cost-center`;

  return new Promise((resolve, reject) => {
    new ApiCore()
      .getgeneralRequest(url, id, type, requestPolicy)
      .then((res) => {
        resolve(res);
      });
  });
}
export function getTravelRequest(id, type) {
  let url = `${API_URL}/travel-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id, type).then((res) => {
      resolve(res);
    });
  });
}
export function getPerDiem(id, type, requestPolicy) {
  let url = `${API_URL}/request-perdiem`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id, type, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function getCashInAdvance(id, type, requestPolicy) {
  let url = `${API_URL}/request-advcash`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id, type, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function submitTravelRequest(id) {
  let url = `${API_URL}/request-submit`;
  let body = {
    requestId: id,
    status: "submitted",
  };
  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getDashboardNumber(id) {
  let url = `${API_URL}/request-count`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function getTotalRequest(empId) {
  let url = `${API_URL}/total-travel-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, empId).then((res) => {
      resolve(res);
    });
  });
}
export function allTravelRequestDashboard(empId) {
  let url = `${API_URL}/travel-request-list`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, empId).then((res) => {
      resolve(res);
    });
  });
}
export function getPendingRequest(empId) {
  let url = `${API_URL}/pending-travel-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, empId).then((res) => {
      resolve(res);
    });
  });
}
export function getToBeApproved(empId) {
  let url = `${API_URL}/request-to-approved`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, empId).then((res) => {
      resolve(res);
    });
  });
}
export function postOtherExpense(body, requestPolicy) {
  let url = `${API_URL}/other-expense`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuthWithPolicy(url, body, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function getOtherExpense(id, type, requestPolicy) {
  let url = `${API_URL}/other-expense`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id, type, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function getTransport(id, type, requestPolicy) {
  let url = `${API_URL}/request-transport`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id, type, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function postTransport(type, body, requestPolicy) {
  let url = `${API_URL}/request-transport`;
  let params = {
    transportType: type,
  };
  return new Promise((resolve, reject) => {
    new ApiCore()
      .postWithParamsAndPolicy(url, params, body, requestPolicy)
      .then((res) => {
        resolve(res);
      });
  });
}
export function withdrawRequest(body) {
  let url = `${API_URL}/request-withdrawn`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function cancelRequest(body) {
  let url = `${API_URL}/cancel-request `;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getNeedHelp() {
  let url = `${API_URL}/need-help `;

  return new Promise((resolve, reject) => {
    new ApiCore().getAuth(url).then((res) => {
      resolve(res);
    });
  });
}
export function getApprovedetails(id, type) {
  let url = `${API_URL}/request-detail`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id, type).then((res) => {
      resolve(res);
    });
  });
}

export function getPdfdetails(id, paramtype) {
  let url = `${API_URL}/request-export-pdf?${paramtype}=${id}`;

  return new Promise((resolve, reject) => {
    new ApiCore().getPdfRequest(url).then((res) => {
      resolve(res);
    });
  });
}

export function getExpensePdfdetails(id) {
  let url = `${API_URL}/expense-export-pdf?claimNumber=${id}`;

  return new Promise((resolve, reject) => {
    new ApiCore().getPdfRequest(url).then((res) => {
      resolve(res);
    });
  });
}

export function approval(body, urlpart) {
  let url = `${API_URL}/${urlpart}`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function writeToUs(body) {
  let url = `${API_URL}/need-help`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getBulletinBoard() {
  let url = `${API_URL}/get-bulletin`;

  return new Promise((resolve, reject) => {
    new ApiCore().getpolicy(url).then((res) => {
      resolve(res);
    });
  });
}
export function sendBackByManager(body) {
  let url = `${API_URL}/request-send-back`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function clearRequest(body) {
  let url = `${API_URL}/clear-data `;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getNotification(id) {
  let url = `${API_URL}/notification`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function updateNotificationStatus(body) {
  let url = `${API_URL}/notification`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function pullRequestList(empId) {
  let url = `${API_URL}/pull-request-list`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, empId).then((res) => {
      resolve(res);
    });
  });
}

export function expensetransport(formdata, type) {
  let url = `${API_URL}/expense-transport`;
  let params = {
    transportType: type,
  };
  return new Promise((resolve, reject) => {
    new ApiCore().postforexpensetransport(url, formdata, params).then((res) => {
      resolve(res);
    });
  });
}
export function expensehotel(formdata) {
  let url = `${API_URL}/expense-hotel`;

  return new Promise((resolve, reject) => {
    new ApiCore().postforexpensetransport(url, formdata).then((res) => {
      resolve(res);
    });
  });
}
export function expensegethotel(id) {
  let url = `${API_URL}/expense-hotel`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequestForExpense(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function PreviewFile(name) {
  let url = `${API_URL}/preview-file`;

  return new Promise((resolve, reject) => {
    new ApiCore().getPreviewFile(url, name).then((res) => {
      resolve(res);
    });
  });
}
export function currencyList() {
  let url = `${API_URL}/get-currency `;

  return new Promise((resolve, reject) => {
    new ApiCore().getAuth(url).then((res) => {
      resolve(res);
    });
  });
}
export function exchangeRate(billCurrency, baseCurrency) {
  let url = `${API_URL}/exchange-rate `;

  return new Promise((resolve, reject) => {
    new ApiCore()
      .getExchangeRate(url, billCurrency, baseCurrency)
      .then((res) => {
        resolve(res);
      });
  });
}
export function ocr(file) {
  let url = `${API_URL}/file-ocr`;

  return new Promise((resolve, reject) => {
    new ApiCore().postforexpensetransport(url, file).then((res) => {
      resolve(res);
    });
  });
}
export function pull(body) {
  let url = `${API_URL}/pull-request-setup`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function expenseClear(body) {
  let url = `${API_URL}/clear-expense-data`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getExpenseTransport(id) {
  let url = `${API_URL}/expense-transport`;

  return new Promise((resolve, reject) => {
    new ApiCore().getTransportRequest(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function ExpenseCancelRequest(body) {
  let url = `${API_URL}/cancel-expense-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function allExpenseTravelRequestDashboard(empId) {
  let url = `${API_URL}/expense-request-list`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, empId).then((res) => {
      resolve(res);
    });
  });
}
export function expenseTravelRequest(body) {
  let url = `${API_URL}/expense-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getExpenseTravelRequest(id) {
  let url = `${API_URL}/expense-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().getTransportRequest(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function expensePerDiemRequest(body, requestPolicy) {
  let url = `${API_URL}/expense-perdiem`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuthWithPolicy(url, body, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function getExpensePerDiem(id, requestPolicy) {
  let url = `${API_URL}/expense-perdiem`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequestForExpense(url, id, requestPolicy).then((res) => {
      resolve(res);
    });
  });
}
export function getExpenseCashInAdvance(id) {
  let url = `${API_URL}/expense-advcash`;

  return new Promise((resolve, reject) => {
    new ApiCore().getTransportRequest(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function getExpenseGeneralData(id) {
  let url = `${API_URL}/expense-cost-center`;

  return new Promise((resolve, reject) => {
    new ApiCore().getgeneralRequest(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function submitExpenseRequest(id) {
  // let url = `${API_URL}/expenserequest-submit`;
  let url = `${API_URL}/expenserequest-status-approval`;

  let body = {
    requestId: id,
    comment: "",
  };
  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function expensepostOtherExpense(body) {
  let url = `${API_URL}/expense-other-expense`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuthWithPolicy(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function ExpensewithdrawRequest(body) {
  let url = `${API_URL}/expenserequest-withdrawn`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getExpenseDashboardNumber(id) {
  let url = `${API_URL}/expense-request-count`;

  return new Promise((resolve, reject) => {
    new ApiCore().getEmpId(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function getExpenseApprovedetails(id) {
  let url = `${API_URL}/expense-request-detail`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function approvalExpense(body) {
  // let url = `${API_URL}/expenserequest-${urlpart}`;
  let url = `${API_URL}/expenserequest-status-approval`;


  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function sendBackExpense(body) {
  let url = `${API_URL}/request-send-back`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getExpenseOtherExpense(id) {
  let url = `${API_URL}/expense-other-expense`;

  return new Promise((resolve, reject) => {
    new ApiCore().getRequest(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function submitReceiptPost(formdata) {
  let url = `${API_URL}/expense-receipt-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().postforexpensetransport(url, formdata).then((res) => {
      resolve(res);
    });
  });
}
export function submitReceiptList(claimno) {
  let url = `${API_URL}/expense-receipt-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().getSubmitReceiptList(url, claimno).then((res) => {
      resolve(res);
    });
  });
}
export function submitReceiptGet(id) {
  let url = `${API_URL}/expense-receipt-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().getSubmitReceipt(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function deleteClaims(body) {
  let url = `${API_URL}/expense-receipt-request`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function submitClaimRequest(body) {
  // let url = `${API_URL}/expense-receipt-submit`;
  let url = `${API_URL}/expense-receipt-status-approval`;


  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function ClaimwithdrawRequest(body) {
  let url = `${API_URL}/expense-receipt-withdrawn`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function claimApprove(body) {
  // let url = `${API_URL}/expense-receipt-${urlpart}`;
  let url = `${API_URL}/expense-receipt-status-approval`;


  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function sendBackClaim(body) {
  let url = `${API_URL}/expense-receipt-sendback`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function ClaimcancelRequest(body) {
  let url = `${API_URL}/expense-receipt-cancel`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}

export function debitAccountNumber() {
  let url = `${API_URL}/get-debit-account`;

  return new Promise((resolve, reject) => {
    new ApiCore().getpolicy(url).then((res) => {
      resolve(res);
    });
  });
}
export function downloadRecipt(header) {
  let url = `${API_URL}/bank-report`;

  return new Promise((resolve, reject) => {
    new ApiCore().getReceipt(url, header).then((res) => {
      resolve(res);
    });
  });
}
export function creditCardOcr(formdata) {
  let url = `${API_URL}/credit-card-ocr`;

  return new Promise((resolve, reject) => {
    new ApiCore().postforexpensetransport(url, formdata).then((res) => {
      resolve(res);
    });
  });
}
export function creditCardInsert(formdata) {
  let url = `${API_URL}/credit-card-data`;

  return new Promise((resolve, reject) => {
    new ApiCore().postforexpensetransport(url, formdata).then((res) => {
      resolve(res);
    });
  });
}
export function getCreditCard(header) {
  let url = `${API_URL}/credit-card-data`;

  return new Promise((resolve, reject) => {
    new ApiCore().getReceipt(url, header).then((res) => {
      resolve(res);
    });
  });
}
export function auditRecipt(header) {
  let url = `${API_URL}/audit-report`;

  return new Promise((resolve, reject) => {
    new ApiCore().getReceipt(url, header).then((res) => {
      resolve(res);
    });
  });
}
export function countryNameExpenseAdmin() {
  let url = `${API_URL}/get-country`;

  return new Promise((resolve, reject) => {
    new ApiCore().getpolicy(url).then((res) => {
      resolve(res);
    });
  });
}
export function costCenterExpenseAdmin() {
  let url = `${API_URL}/get-costcenter`;

  return new Promise((resolve, reject) => {
    new ApiCore().getpolicy(url).then((res) => {
      resolve(res);
    });
  });
}
export function managerIdExpenseAdmin(header) {
  let url = `${API_URL}/get-manager`;

  return new Promise((resolve, reject) => {
    new ApiCore().getReceipt(url, header).then((res) => {
      resolve(res);
    });
  });
}
export function businessUnitExpenseAdmin() {
  let url = `${API_URL}/get-businessunit`;

  return new Promise((resolve, reject) => {
    new ApiCore().getpolicy(url).then((res) => {
      resolve(res);
    });
  });
}
export function employeeIdExpenseAdmin(header) {
  let url = `${API_URL}/get-employees`;

  return new Promise((resolve, reject) => {
    new ApiCore().getReceipt(url, header).then((res) => {
      resolve(res);
    });
  });
}
export function offBoard(body) {
  let url = `${API_URL}/deboard-employee`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function financePersonExpenseAdmin() {
  let url = `${API_URL}/get-finance-person`;

  return new Promise((resolve, reject) => {
    new ApiCore().getpolicy(url).then((res) => {
      resolve(res);
    });
  });
}
export function onBoard(body) {
  let url = `${API_URL}/employee-operations`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function onUpdateExpenseAdmin(body) {
  let url = `${API_URL}/employee-update`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function selfInsight(body) {
  let url = `${API_URL}/insight-self`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function reporteeInsight(body) {
  let url = `${API_URL}/insight-reportees`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function adminInsight(body) {
  let url = `${API_URL}/insight-admin-person`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function reporteDropdown(header) {
  let url = `${API_URL}/get-manager-reportees`;

  return new Promise((resolve, reject) => {
    new ApiCore().getReceipt(url, header).then((res) => {
      resolve(res);
    });
  });
}
export function abandonedReq(body) {
  let url = `${API_URL}/request-abandoned`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getCategories() {
  let url = `${API_URL}/get-category`;

  return new Promise((resolve, reject) => {
    new ApiCore().getpolicy(url).then((res) => {
      resolve(res);
    });
  });
}
export function getDatesForPerDiem(id) {
  let url = `${API_URL}/date-list`;

  return new Promise((resolve, reject) => {
    new ApiCore().getTransportRequest(url, id).then((res) => {
      resolve(res);
    });
  });
}
export function costenDriveget(header) {
  let url = `${API_URL}/costen-drive-list`;

  return new Promise((resolve, reject) => {
    new ApiCore().getReceipt(url, header).then((res) => {
      resolve(res);
    });
  });
}
export function costenDriveUpload(formData) {
  let url = `${API_URL}/costen-drive-upload`;

  return new Promise((resolve, reject) => {
    new ApiCore().postforcostendrive(url, formData).then((res) => {
      resolve(res);
    });
  });
}
export function costenDriveDelete(body) {
  let url = `${API_URL}/costen-drive-ops`;

  return new Promise((resolve, reject) => {
    new ApiCore().postDeleteCostenDrive(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function downloadFile(header) {
  let url = `${API_URL}/download-file`;

  return new Promise((resolve, reject) => {
    new ApiCore().getReceipt(url, header).then((res) => {
      resolve(res);
    });
  });
}
export function getCurrencyList() {
  let url = `${API_URL}/get-multicurrency`;

  return new Promise((resolve, reject) => {
    new ApiCore()
      .getEmpId(url, sessionStorage.getItem("employeeId"))
      .then((res) => {
        resolve(res);
      });
  });
}
export function getFlags() {
  let url = `${API_URL}/get-flags`;

  return new Promise((resolve, reject) => {
    new ApiCore().getpolicy(url).then((res) => {
      resolve(res);
    });
  });
}
export function updateEstimateCostHotel(body) {
  let url = `${API_URL}/expense-admin-hotel`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function updateEstimateCostTransport(body) {
  let url = `${API_URL}/admin-request-transport`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function postPullTravelAdvance(body) {
  let url = `${API_URL}/expense-advcash`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}
export function getExpenseDropDown() {
  let url = `${API_URL}/get-expenserequest-details`;

  return new Promise((resolve, reject) => {
    new ApiCore().getgeneral(url).then((res) => {
      resolve(res);
    });
  });
}

export function getClaimDetailsForTravelOverview(id) {
  let url = `${API_URL}/claim-request-detail`;
  let params = {
    claimNumber:id,
  };
  return new Promise((resolve, reject) => {
    new ApiCore()
      .getWithParams(url, params)
      .then((res) => {
        resolve(res);
      });
  });
}
export function sendBackByManagerExpense(body) {
  let url = `${API_URL}/expenserequest-send-back`;

  return new Promise((resolve, reject) => {
    new ApiCore().postAuth(url, body).then((res) => {
      resolve(res);
    });
  });
}