import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Popover, Row, Table, message, Space, Input } from "antd";
import SideBar from "./SideBar";
import Header from "./Header";
import "./dashboard.css";
import Footer from "./Footer";
import "./tobeapproved.css";
import { allTravelRequestDashboard } from "../services/ApiService";
import moment from "moment";
import { WarningOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

function TotalRequest() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const [totalRequest, setTotalRequest] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const columns = [];

  const empId = sessionStorage.employeeId;
  useEffect(() => {
    allTravelRequestDashboard(empId).then((res) => {
      if (res.responseCode === 200) {
        res.data.totalRequest.map((item) => {
          // Format the "start_date" field to "dd-mm-yyyy"
          if (item.start_date) {
            item.start_date = moment(item.start_date).format("DD-MM-YYYY");
          }
          if (item.to_be_approved) {
            item.to_be_approved = moment(item.to_be_approved).format(
              "DD-MM-YYYY"
            );
          }
          return item;
        });
        setTotalRequest(res.data.totalRequest);
      } else {
        message.error(res.responseMessage);
      }
    });
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: "10px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          border: "1px solid #d9d9d9",
          width: "200 px",
          position: "absolute",
          left: 0,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  if (totalRequest.length > 0) {
    const firstDataItem = totalRequest[0];
    for (const key in firstDataItem) {
      if (
        firstDataItem.hasOwnProperty(key) &&
        key !== "rejectedBy" &&
        key !== "reasonReject"
      ) {
        let column = {
          title: key.toUpperCase().replaceAll("_", " "),
          dataIndex: key,
          key: key,
        };

        if (key === "start_date") {
          column = {
            title: key.toUpperCase().replaceAll("_", " "),
            dataIndex: key,
            key: key,
            sorter: (a, b) => {
              const [dayA, monthA, yearA] = a.start_date.split("-");
              const [dayB, monthB, yearB] = b.start_date.split("-");
              return (
                new Date(yearA, monthA - 1, dayA) -
                new Date(yearB, monthB - 1, dayB)
              );
            },
            render: (date) => date || "N/A",
          };
        }

        if (key === "request_id") {
          column = {
            title: key.toUpperCase().replaceAll("_", " "),
            dataIndex: key,
            key: key,
            ...getColumnSearchProps("request_id"),
          };
        }

        if (key === "status") {
          column.render = (text, record) => {
            if (record.reasonReject) {
              return (
                <Popover
                  content={
                    <span>
                      {record.rejectedBy} : {record.reasonReject}
                    </span>
                  }
                >
                  <span>
                    {text} <WarningOutlined style={{ color: "red" }} />
                  </span>
                </Popover>
              );
            } else {
              return text;
            }
          };
        }
        columns.push(column);
      }
    }
    columns.push({
      key: "detail",
      render: (text, record) => (
        <Button onClick={() => handleDetailClick(record)}>Details</Button>
      ),
    });
  }

  const handleDetailClick = (record) => {
    navigate("/request-detail", {
      state: {
        id: record.request_id,
        status: record.status,
        requestPolicy: record.request_policy,
      },
    });
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={false}
            travel={true}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={false} travel={true} />
          <div
            style={{
              backgroundColor: "white",
              display: "block",
              padding: "1rem",
              marginRight: "1.5rem",
              borderRadius: "15px",
              marginTop: "2rem",
              // overflow: "auto",
              height: "75vh",
            }}
            className="scrollable-container"
          >
            {totalRequest ? (
              <Table
                dataSource={totalRequest}
                pagination={true}
                scroll={{ x: 1490, y: 290 }}
                columns={columns}
                rowKey="id"
                // pagination={{ pageSize: 5 }}
                // scroll={{
                //   x: 1300,
                //   y: 260,
                // }}
              />
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
      <Footer />
      {/* {details?<div style={{display:"none"}}><Details id={id}/></div>:<></>} */}
    </div>
  );
}

export default TotalRequest;
