import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Popover, Row, Table, message, Space, Input } from "antd";
import SideBar from "./SideBar";
import Header from "./Header";
import "./dashboard.css";
import Footer from "./Footer";
import "./tobeapproved.css";
import { useNavigate } from "react-router-dom";
import { allTravelRequestDashboard } from "../services/ApiService";
import moment from "moment";
import { amount, requesteid } from "../redux/actions";
import { useDispatch } from "react-redux";
import { WarningOutlined, SearchOutlined } from "@ant-design/icons"; // Import the WarningOutlined icon

const formatColumnName = (columnName) => {
  // Convert snake_case to title case (e.g., request_id to Request Id)
  if (columnName === "to_be_approved") {
    return <span>To Be Approved</span>;
  }
  const words = columnName.split("_");
  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return formattedWords.join(" ");
};

function OpenRequest() {
  const [collapsed, setCollapsed] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const empId = sessionStorage.employeeId;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    allTravelRequestDashboard(empId).then((res) => {
      if (res.responseCode === 200) {
        res.data.openRequest.map((item) => {
          if (item.start_date) {
            item.start_date = moment(item.start_date).format("DD-MM-YYYY");
          }
          if (item.to_be_approved) {
            item.to_be_approved = moment(item.to_be_approved).format(
              "DD-MM-YYYY"
            );
          }
          return item;
        });

        if (res.data.openRequest.length > 0) {
          const filteredColumns = Object.keys(res.data.openRequest[0])
            .filter((key) => key !== "rejectedBy" && key !== "reasonReject") // Exclude "rejectedBy" and "reasonReject"
            .map((key) => ({
              title: formatColumnName(key),
              dataIndex: key,
              key,
              ...(key === "request_id" ? getColumnSearchProps(key) : {}), // Apply search for request_id
              ...(key === "start_date"
                ? {
                    sorter: (a, b) =>
                      moment(a.start_date, "DD-MM-YYYY") -
                      moment(b.start_date, "DD-MM-YYYY"),
                  }
                : {}),
              render: (text, record) => {
                if (key === "status" && record.reasonReject) {
                  return (
                    <Popover
                      content={
                        <span>
                          {record.rejectedBy} : {record.reasonReject}
                        </span>
                      }
                    >
                      <span>
                        {text} <WarningOutlined style={{ color: "red" }} />
                      </span>
                    </Popover>
                  );
                }
                return text;
              },
            }));

          setColumns([
            ...filteredColumns,
            {
              title: "Details",
              key: "details",
              render: (text, record) => (
                <Button onClick={() => handleDetailClick(record)}>
                  View Details
                </Button>
              ),
            },
          ]);

          setData(res.data.openRequest);
        }
      } else {
        message.error(res.responseMessage);
      }
    });
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  console.log(searchedColumn, "col");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: "10px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          border: "1px solid #d9d9d9",
          width: "200 px",
          position: "absolute",
          left: 0,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleDetailClick = (record) => {
    if (record.request_id.charAt(0) == "D") {
      sessionStorage.setItem("T", "D");
      dispatch(requesteid(record.request_id));
      dispatch(amount(record.total_amount));
      navigate("/travel-page", { state: { data: "open" } });
    } else if (record.request_id.charAt(0) == "I") {
      sessionStorage.setItem("T", "I");
      dispatch(requesteid(record.request_id));
      navigate("/travel-page", { state: { data: "open" } });
    }
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={false}
            travel={true}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={false} travel={true} />
          <div
            style={{
              backgroundColor: "white",
              display: "block",
              padding: "1rem",
              marginRight: "1.5rem",
              borderRadius: "15px",
              marginTop: "2rem",
              overflow: "auto",
              height: "75vh",
            }}
          >
            {data ? (
              <Table
                columns={columns}
                dataSource={data}
                rowKey="request_id"
                pagination={true}
                scroll={{ x: 1490, y: 290 }}
              />
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default OpenRequest;
